define("discourse/plugins/discourse-ai/discourse/components/ai-full-page-search", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/components/d-toggle-switch", "discourse/controllers/full-page-search", "discourse/lib/ajax", "discourse/lib/search", "discourse-common/helpers/d-icon", "discourse-i18n", "float-kit/components/d-tooltip", "discourse/plugins/discourse-ai/discourse/components/ai-indicator-wave", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _didUpdate, _service, _dToggleSwitch, _fullPageSearch, _ajax, _search, _dIcon, _discourseI18n, _dTooltip, _aiIndicatorWave, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiFullPageSearch extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "searchPreferencesManager", [_service.service]))();
    #searchPreferencesManager = (() => (dt7948.i(this, "searchPreferencesManager"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "searching", [_tracking.tracked]))();
    #searching = (() => (dt7948.i(this, "searching"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "AiResults", [_tracking.tracked], function () {
      return [];
    }))();
    #AiResults = (() => (dt7948.i(this, "AiResults"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "showingAiResults", [_tracking.tracked], function () {
      return false;
    }))();
    #showingAiResults = (() => (dt7948.i(this, "showingAiResults"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "sortOrder", [_tracking.tracked], function () {
      return this.args.sortOrder;
    }))();
    #sortOrder = (() => (dt7948.i(this, "sortOrder"), void 0))();
    initialSearchTerm = this.args.searchTerm;
    constructor() {
      super(...arguments);
      this.appEvents.on("full-page-search:trigger-search", this, this.onSearch);
      this.onSearch();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("full-page-search:trigger-search", this, this.onSearch);
    }
    onSearch() {
      if (!this.searchEnabled) {
        return;
      }
      this.initialSearchTerm = this.args.searchTerm;
      this.searching = true;
      this.resetAiResults();
      return this.performHyDESearch();
    }
    static #_9 = (() => dt7948.n(this.prototype, "onSearch", [_object.action]))();
    get disableToggleSwitch() {
      if (this.searching || this.AiResults.length === 0 || !this.validSearchOrder) {
        return true;
      }
    }
    get validSearchOrder() {
      return this.sortOrder === 0;
    }
    get searchStateText() {
      if (!this.validSearchOrder) {
        return _discourseI18n.default.t("discourse_ai.embeddings.semantic_search_results.unavailable");
      }
      // Search loading:
      if (this.searching) {
        return _discourseI18n.default.t("discourse_ai.embeddings.semantic_search_loading");
      }
      // We have results and we are showing them
      if (this.AiResults.length && this.showingAiResults) {
        return _discourseI18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle", {
          count: this.AiResults.length
        });
      }
      // We have results but are hiding them
      if (this.AiResults.length && !this.showingAiResults) {
        return _discourseI18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle_hidden", {
          count: this.AiResults.length
        });
      }
      // Typing to search:
      if (this.AiResults.length === 0 && this.searchTerm !== this.initialSearchTerm) {
        return _discourseI18n.default.t("discourse_ai.embeddings.semantic_search_results.new");
      }
      // No results:
      if (this.AiResults.length === 0) {
        return _discourseI18n.default.t("discourse_ai.embeddings.semantic_search_results.none");
      }
    }
    get searchTerm() {
      if (this.initialSearchTerm !== this.args.searchTerm) {
        this.initialSearchTerm = undefined;
      }
      return this.args.searchTerm;
    }
    get searchEnabled() {
      return this.args.searchType === _fullPageSearch.SEARCH_TYPE_DEFAULT && (0, _search.isValidSearchTerm)(this.searchTerm, this.siteSettings) && this.validSearchOrder;
    }
    toggleAiResults() {
      if (this.showingAiResults) {
        this.args.addSearchResults([], "topic_id");
      } else {
        this.args.addSearchResults(this.AiResults, "topic_id");
      }
      this.showingAiResults = !this.showingAiResults;
    }
    static #_10 = (() => dt7948.n(this.prototype, "toggleAiResults", [_object.action]))();
    resetAiResults() {
      this.AiResults = [];
      this.showingAiResults = false;
      this.args.addSearchResults([], "topic_id");
    }
    static #_11 = (() => dt7948.n(this.prototype, "resetAiResults", [_object.action]))();
    performHyDESearch() {
      this.resetAiResults();
      (0, _ajax.ajax)("/discourse-ai/embeddings/semantic-search", {
        data: {
          q: this.searchTerm
        }
      }).then(async results => {
        const model = (await (0, _search.translateResults)(results)) || {};
        if (model.posts?.length === 0) {
          return;
        }
        model.posts.forEach(post => {
          post.generatedByAi = true;
        });
        this.AiResults = model.posts;
      }).finally(() => {
        this.searching = false;
      });
    }
    sortChanged() {
      if (this.sortOrder !== this.args.sortOrder) {
        this.sortOrder = this.args.sortOrder;
        if (this.validSearchOrder) {
          this.onSearch();
        } else {
          this.showingAiResults = false;
          this.resetAiResults();
        }
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "sortChanged", [_object.action]))();
    static #_13 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span {{didUpdate this.sortChanged @sortOrder}}></span>
        <div class="semantic-search__container search-results" role="region">
          <div class="semantic-search__results">
            <div
              class="semantic-search__searching
                {{if this.searching 'in-progress'}}
                {{unless this.validSearchOrder 'unavailable'}}"
            >
              <DToggleSwitch
                disabled={{this.disableToggleSwitch}}
                @state={{this.showingAiResults}}
                class="semantic-search__results-toggle"
                {{on "click" this.toggleAiResults}}
              />
    
              <div class="semantic-search__searching-text">
                {{icon "discourse-sparkles"}}
                {{this.searchStateText}}
              </div>
    
              {{#if this.validSearchOrder}}
                <AiIndicatorWave @loading={{this.searching}} />
              {{/if}}
    
              {{#unless this.validSearchOrder}}
    
                <DTooltip
                  @identifier="semantic-search-unavailable-tooltip"
                  class="semantic-search__unavailable-tooltip"
                >
                  <:trigger>
                    {{icon "far-circle-question"}}
                  </:trigger>
                  <:content>
                    {{i18n
                      "discourse_ai.embeddings.semantic_search_unavailable_tooltip"
                    }}
                  </:content>
                </DTooltip>
              {{/unless}}
            </div>
          </div>
        </div>
      
    */
    {
      "id": "V6mUd88D",
      "block": "[[[1,\"\\n    \"],[11,1],[4,[32,0],[[30,0,[\"sortChanged\"]],[30,1]],null],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"semantic-search__container search-results\"],[14,\"role\",\"region\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"semantic-search__results\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"semantic-search__searching\\n            \",[52,[30,0,[\"searching\"]],\"in-progress\"],\"\\n            \",[52,[51,[30,0,[\"validSearchOrder\"]]],\"unavailable\"]]]],[12],[1,\"\\n          \"],[8,[32,1],[[16,\"disabled\",[30,0,[\"disableToggleSwitch\"]]],[24,0,\"semantic-search__results-toggle\"],[4,[32,2],[\"click\",[30,0,[\"toggleAiResults\"]]],null]],[[\"@state\"],[[30,0,[\"showingAiResults\"]]]],null],[1,\"\\n\\n          \"],[10,0],[14,0,\"semantic-search__searching-text\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"discourse-sparkles\"],null]],[1,\"\\n            \"],[1,[30,0,[\"searchStateText\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"validSearchOrder\"]],[[[1,\"            \"],[8,[32,4],null,[[\"@loading\"],[[30,0,[\"searching\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,0,[\"validSearchOrder\"]]],[[[1,\"\\n            \"],[8,[32,5],[[24,0,\"semantic-search__unavailable-tooltip\"]],[[\"@identifier\"],[\"semantic-search-unavailable-tooltip\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n                \"],[1,[28,[32,3],[\"far-circle-question\"],null]],[1,\"\\n              \"]],[]],[[[1,\"\\n                \"],[1,[28,[32,6],[\"discourse_ai.embeddings.semantic_search_unavailable_tooltip\"],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@sortOrder\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-full-page-search.js",
      "scope": () => [_didUpdate.default, _dToggleSwitch.default, _modifier.on, _dIcon.default, _aiIndicatorWave.default, _dTooltip.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiFullPageSearch;
});